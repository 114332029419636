/* Define CSS Variables for Theme in a Scoped Class */
.mobile-home-section-root {
    --bg-color: #080835;
    --gradient-bg: linear-gradient(180deg, #080835, #10104a);
    --accent-color: #f4f7f8;
    --btn-color: #449fff;
    --btn-hover-color: #007bb5;
    --text-color: #ffffff;
    --secondary-text-color: #ddd;
}

/* Base styling for the mobile home section */
.mobile-home-section {
    background: var(--gradient-bg);
    color: var(--text-color);
    margin: 0;
    text-align: center;
    margin: 0; /* Ensure no margin is applied */
    padding: 0; 
    margin-top: 60px;
}

.home-content p {
    font-size: 1.2rem;
    line-height: 1.5;
    color: var(--secondary-text-color);
    margin: 0 auto;
    max-width: 500px;
    border: none;
}

/* Buttons container */
.home-buttons {
    display: flex;
    gap: 1rem;
    justify-content: center;
    position: relative;
    top: -1rem; /* Moves the buttons up */
}

/* Button styles */
.button {
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
    font-weight: bold;
    color: rgb(255, 255, 255);
    background-color: #080835;
    border: none;
    border-radius: 8px; /* Slightly rounded corners */
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-decoration: none;
    display: inline-block;
    text-align: center;
}

/* Specific styles for the download button */
.button.download-btn {
    padding: 1rem 1.5rem; /* Adjust the size */
    font-size: 0.8rem;  /* Increase font size */
}

/* Specific styles for the contact button */
.button.contact-btn {
    padding: 1rem 2.9rem; /* Adjust the size */
    font-size: 0.8rem;  /* Increase font size */
}
.button:hover {
    background-color: var(--btn-hover-color);
    transform: scale(1.1); /* Slight zoom effect */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow on hover */
}
/* Home image */
.home-image-mobile {
    margin-top: 2.4rem;
    display: flex;
    justify-content: center;
}

.home-image-mobile img {
    width: 100%; /* Set a percentage relative to the parent container */
    max-width: 390px; /* Adjust to your preferred maximum size */
    height: auto; /* Maintain aspect ratio */
    border-radius: 50px; /* Optional: rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Optional: subtle shadow */
    animation: fadeIn 1s ease-in-out; /* Optional: fade-in effect */
}

/* Social Icons */
/* General styles for all social icons */
.social-icons {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 1rem; /* Adjust spacing above the buttons */
    color: #f4f7f8;
}

/* GitHub button specific styles */
.github-btn svg {
    fill: #ffffff; /* GitHub color */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    padding: 1.5rem 1rem; /* Adjust the size */
    font-size: 2rem;
}

.github-btn:hover svg {
    transform: scale(1.2);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* LinkedIn button specific styles */
.linkedin-btn svg {
    fill: #ffffff; /* LinkedIn color */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    padding: 1.5rem 2rem; /* Adjust the size */
    font-size: 0.8rem; 
}

.linkedin-btn:hover svg {
    transform: scale(1.2);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Instagram button specific styles */
.instagram-btn svg {
    fill: #ffffff; /* Instagram color */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    padding: 1.5rem 1rem; /* Adjust the size */
    font-size: 0.8rem;
}

.instagram-btn:hover svg {
    transform: scale(1.2);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Animation for the image */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Responsive Design */
@media (max-width: 768px) {
    .home-content h1 {
        font-size: 1.8rem;
    }

    .home-content p {
        font-size: 1rem;
    }

    .btn {
        font-size: 0.9rem;
        padding: 0.7rem 1.2rem;
    }
}

@media (max-width: 480px) {
    .mobile-home-section {
        padding: 1.5rem 0.5rem;
    }

    .home-content h1 {
        font-size: 1.5rem;
    }

    .home-content p {
        font-size: 0.9rem;
    }

    .home-image img {
        max-width: 200px; /* Adjust for smaller screens */
    }
}
