/* Footer Container */
.mobile-footer {
    background-color: #12192c; /* Dark background */
    color: #ffffff;
    text-align: center;
    padding: 1rem 0;
    font-family: Arial, sans-serif;
}

.footer-content { 
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer-content p {
    margin: 0.5rem 0;
    font-size: 0.9rem;
    color: #ccc;
}

.footer-links {
    list-style: none;
    padding: 0;
    margin: 0.5rem 0 0;
    display: flex;
    gap: 1rem;
    font-family: 'Noto Serif', serif;
}

.footer-links li a {
    color: #eef1f1; /* Accent color */
    text-decoration: none;
    font-size: 1rem;
    transition: color 0.3s ease;
}

.footer-links li a:hover {
    color: #fff;
}
