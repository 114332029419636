/* Hero Section Background */
.hero--section--background {
  background: linear-gradient(
      to bottom,
      rgba(17, 6, 57, 0.9) 10%,
      rgba(255, 255, 255, 0) 30%,
      rgba(255, 255, 255, 0) 70%,
      rgba(8, 2, 43, 0.9) 90%
    ),
    url('../../../public/img/BackGroundPic.jpg');
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10vw;
}

/* Hero Section Container */
.hero--section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  max-width: 1200px;
  margin: auto;
}

.hero--content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
}

.hero--title {
  font-family: 'Noto Serif', serif;
  font-size: 3rem;
  font-weight: 700;
  color: #33ccff;
  line-height: 1.2;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.7);
}

.hero--description {
  font-size: 1.2rem;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  line-height: 1.6;
  color: #cceeff;
}

.hero--buttons {
  display: flex;
  gap: 1rem;
}

.hero--button {
  background-color: #080835;
  color: white;
  border: none;
  padding: 0.8rem 2rem;
  font-size: 1rem;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.hero--button:hover {
  background-color: white;
  color: #080835;
}

.hero--image-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.hero--image {
  max-width: 100%;
  max-height: 70vh;
  border-radius: 50%;
  object-fit: cover;
}

.hero--social-icons {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.hero--social-icon {
  font-size: 2rem;
  color: white;
  transition: transform 0.3s ease, color 0.3s ease;
}

.hero--social-icon:hover {
  color: #33ccff;
  transform: scale(1.1);
}

/* Responsive Design */
@media only screen and (max-width: 768px) {
  .hero--section {
    flex-direction: column;
    text-align: center;
    gap: 2rem;
  }

  .hero--content {
    align-items: center;
  }

  .hero--title {
    font-size: 2.5rem;
  }

  .hero--description {
    font-size: 1rem;
  }

  .hero--image-wrapper {
    margin-top: 1rem;
  }
}

@media only screen and (min-width: 1200px) {
  .hero--title {
    font-size: 3.5rem;
  }

  .hero--button {
    font-size: 1.1rem;
    padding: 1rem 2.5rem;
  }
}
