/* Improved Expertise Carousel CSS */

.skills--section--background {
  background: 
    linear-gradient(to bottom, #080835 4%, rgba(237, 231, 231, 0) 10%, rgba(255, 255, 255, 0) 90%, rgba(255, 255, 255, 0) 95%), #ffffff;
  background-size: cover;
  background-position: center;
}

.skills--section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  padding: 40px 5%;
}

.skills--section--heading {
  color: #1c0757;
  font-size: 2.8rem;
  font-weight: 700;
  line-height: 1.2;
  text-align: center;
  margin: 20px 0; /* Add top and bottom margins */
  padding-top: 20px; /* Add extra spacing from the top */
}


.carousel--container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  gap: 20px;
}

.carousel--button {
  background-color: #ffffff;
  border: none;
  font-size: 2rem;
  color: #080835;
  cursor: pointer;
  border-radius: 50%;
  padding: 10px;
  transition: transform 0.2s ease, background-color 0.2s ease;
}



.carousel--slide {
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-grow: 1;
  width: 100%;
}

.skills--section--card {
  flex: 0 1 30%; /* Adjusted for better size with 3 cards */
  min-width: 250px;
  padding: 2rem;
  border-radius: 1rem;
  background: var(--bg-shade);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  perspective: 1000px;
  transform-style: preserve-3d;
  transition: transform 0.6s ease;
}

.card--back {
  transform: rotateY(180deg);
}

.show-skills-button, .back-button {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: bold;
  color: #ffffff;
  background-color: #1c0757;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.show-skills-button:hover, .back-button:hover {
  background-color: #080835;
}

.skills--placeholder {
  font-size: 1.2rem;
  color: #1c0757;
  text-align: center;
}

.skills--section--card:hover {
  transform: translateY(-5px);
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.2);
}

.skills--section--img {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border-radius: 50%;
  background: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  width: 100px; /* Reduced size for the circle */
  height: 100px; /* Maintain aspect ratio */
}

.skills--section--img img {
  width: 70px; /* Reduced size for the image */
  height: 70px;
  object-fit: contain;
}

.skills--section--card--content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  width: 100%;
}

.skills--section--title {
  color: #1c0757;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.3;
  margin: 0px;
}

.skills--section--description {
  color: #333;
  font-size: 1rem;
  line-height: 1.6;
  margin: 0;
  text-align: justify;
}

@media (max-width: 768px) {
  .carousel--container {
    flex-direction: column;
    align-items: center;
  }

  .carousel--slide {
    flex-direction: column;
    gap: 30px;
    max-width: 100%;
  }

  .skills--section--card {
    min-width: 100%;
  }

  .skills--section--description {
    font-size: 0.9rem;
  }
}
.skills--section--card.flipped {
  transform: rotateY(180deg);
}

/* Styling for the back card categories */
.skills--categories {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.skills--category {
  padding: 1rem;
  background: #f8f9fa; /* Light background for sub-cards */
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.skills--category:hover {
  background-color: #e0e0e0; /* Highlight on hover */
  transform: translateY(-3px); /* Slight lift effect */
}

.skills--category--title {
  font-size: 1.2rem;
  font-weight: bold;
  color: #1c0757;
  margin: 0;
}

.skills--category ul {
  margin-top: 10px;
  padding-left: 20px;
  list-style-type: disc;
  font-size: 1rem;
  color: #333;
  display: none; /* Initially hidden */
}

.skills--category ul li {
  margin: 5px 0;
}

.skills--category ul.active {
  display: block; /* Show technologies when the category is expanded */
}

.skills--category.open ul {
  display: block; /* Expand technologies when the category is active */
}

/* Styling for active state */
.skills--category.open {
  background-color: #d1e7fd; /* Slightly different color for active state */
  border-color: #91caff;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .skills--categories {
    gap: 15px;
  }

  .skills--category {
    font-size: 1rem;
  }

  .skills--category--title {
    font-size: 1rem;
  }
}
