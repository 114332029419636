/* General section styling */
.university-section {
    text-align: center;
    padding: 1.5rem 1rem;
    background-color: #eafaff;
    font-family: 'Roboto', sans-serif;
    color: #080835;
    position: relative;
    overflow: hidden;
}

/* Section title styling */
.section-title {
    font-size: 2.5rem;
    color: #080835;
    margin-bottom: 2rem;
    font-weight: bold;
    text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
}

/* Container for university cards */
.university-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 500px;
    margin-bottom: 3rem;
}

/* Individual university card */
.university-card {
    background-color: #ffffff;
    border-radius: 12px;
    padding: 3rem;
    width: 400px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
    text-align: center;
    position: absolute;
    opacity: 0;
    transform: scale(0.8);
    transition: opacity 0.5s ease, transform 0.5s ease;
}

/* Active university card */
.university-card.active {
    opacity: 1;
    transform: scale(1);
    position: relative;
}

/* University image */
.university-image {
    width: 100%;
    max-height: 200px;
    object-fit: contain;
    margin-bottom: 1.5rem;
    border-radius: 8px;
}

/* University title */
.university-title {
    font-size: 1.6rem; /* Reduced font size */
    color: #080835;
    font-weight: bold;
    margin-bottom: 0.8rem;
}

/* University date */
.university-date {
    margin-bottom: 1.5rem;
    text-align: left;
    font-size: 1.2rem;
    color: #10104a;
    font-family: 'Noto Serif', serif;
    font-weight: bold; /* Added property to make text bold */
}


/* University description */
.university-description {
    font-size: 1.2rem;
    color: #333333;
    line-height: 1.6;
    margin-bottom: 1.5rem;
    text-align: left; /* Align to the left */
}

/* Link to visit university site */
.university-link {
    display: inline-block;
    padding: 0.8rem 1.5rem;
    background-color: #080835; /* Updated button color */
    color: #ffffff;
    font-size: 1.1rem;
    text-decoration: none;
    border-radius: 8px;
    transition: background-color 0.3s ease;
}

.university-link:hover {
    background-color: #005f8d;
}

/* Dots navigation container */
.dots-container {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
}

/* Individual dots */
.dot {
    width: 14px;
    height: 14px;
    margin: 0 6px;
    background-color: #080835;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s ease;
    opacity: 0.6;
}

.dot.active {
    background-color: #080835;
    opacity: 1;
}

/* Responsive Design */
@media (max-width: 768px) {
    .section-title {
        font-size: 2rem;
        margin-bottom: 1.5rem;
    }

    .university-card {
        width: 350px;
        padding: 2.5rem;
    }

    .university-title {
        font-size: 1.4rem; /* Adjusted for smaller screens */
    }

    .university-description {
        font-size: 1.1rem;
    }
}

@media (max-width: 480px) {
    .university-section {
        padding: 1.5rem 0.5rem;
    }

    .section-title {
        font-size: 1.8rem;
        margin-bottom: 1.5rem;
    }

    .university-card {
        width: 300px;
        padding: 2rem;
    }

    .university-title {
        font-size: 1.3rem;
    }

    .university-description {
        font-size: 1rem;
    }
}
