/* Define CSS Variables for Theme in a Scoped Class */
.mobile-header-root {
    --bg-color: #080835;
    --accent-color: #00d4ff;
    --text-color: #fff; 
}
 
/* Base Header Styling */
.mobile-header {
    background-color: var(--bg-color);
    color: var(--text-color);
    padding: 0.5rem 1rem;
    display: flex;
    flex-direction: column;
    font-family: Arial, sans-serif;

    position: fixed;
    top: 0;
    left: 0;
    width: 95%;
    z-index: 1000;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}



.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo a {
    font-size: 1.8rem;
    font-weight: bold;
    color: var(--accent-color);
    text-decoration: none;
}

/* Menu Button for Mobile */
.menu-icon {
    background: none;
    border: none;
    color: var(--text-color);
    font-size: 1.8rem;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.menu-icon:focus-visible {
    outline: 2px solid var(--accent-color);
}

/* Navigation Links Styling */
.nav-links {
    list-style: none;
    display: none;
    flex-direction: column;
    align-items: center;
    margin: 0;
    padding: 0;
    transition: max-height 0.3s ease, opacity 0.3s ease;
    max-height: 0;
    overflow: hidden;
    opacity: 0;

    color: var(--text-color);
    text-decoration: none;
    font-size: 1.2rem;
    font-family: 'Noto Serif', serif; /* Add this line */
    transition: color 0.3s ease;
}

.nav-links.active {
    display: flex;
    max-height: 300px; /* Adjust based on the number of items */
    opacity: 1;
}

.nav-links li {
    margin: 0.5rem 0;
}

.nav-links a {
    color: var(--text-color);
    text-decoration: none;
    font-size: 1.2rem;
    transition: color 0.3s ease;
}

.nav-links a:hover,
.nav-links a:focus-visible {
    color: var(--accent-color);
}

/* Responsive Styling for Larger Screens */
@media (min-width: 768px) {
    .menu-icon {
        display: none; /* Hide menu button */
    }

    .nav-links {
        display: flex; /* Always show links */
        flex-direction: row;
        justify-content: space-around;
        max-height: none;
        opacity: 1;
    }

    .nav-links li {
        margin: 0 1rem;
    }
}
