/* About Us */
.about--section {
  display: grid;
  grid-template-columns: 1fr 2fr; /* Image takes 1/3 and text takes 2/3 of the width */
  align-items: center; /* Vertically align content */
  gap: 20px; /* Gap between the image and text */
  padding: 50px;
  background: linear-gradient(135deg, #f5fcff, #ffffff);
  border-radius: 15px;
  box-shadow: 100 100px 100px #f5fcff;
  max-width: 1200px; /* Ensure the card doesn't stretch too wide */
  margin: 0 auto; /* Center the card horizontally */
}

.about--section--img > img {
  width: 100%; /* Make the image responsive */
  height: auto;
  border-radius: 15px; /* Rounded corners for a modern look */
  box-shadow: 0 4px 35px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
}

.about--section--content {
  font-family: 'Noto Serif', serif; /* Consistent font family */
  color: #333;
  padding: 20px; /* Padding inside the text area */
  text-align: center; /* Center-align all text */
  display: flex; /* Use flexbox for alignment */
  flex-direction: column; /* Stack items vertically */
  justify-content: center; /* Center items vertically */
  align-items: center; /* Center items horizontally */
}

.section--title {
  font-size: 28px; /* Increased title size for hierarchy */
  font-weight: bold;
  color: #080835;
  margin-bottom: 20px;
}

.about--section--description {
  font-size: 16px; /* Reduced font size for better readability */
  line-height: 1.6;
  color: #333;
  max-width: 600px; /* Limit the text width for readability */
  margin: 0 auto; /* Center the text horizontally */
}

.about--section--description strong {
  color: #1a73e8; /* Highlighted color for key text */
}

@media (max-width: 768px) {
  .about--section {
      grid-template-columns: 1fr; /* Stack image and text vertically */
      text-align: center; /* Center-align text for small screens */
  }

  .about--section--img > img {
      width: 80%; /* Resize image for small screens */
      margin: 0 auto;
  }

  .about--section--content {
      text-align: center;
      padding: 20px;
  }
}
