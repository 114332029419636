/* Certificates Section */
.certifications-section {
  padding: 40px 5%;
  background-color: var(--bg-shade, #f8f9fa);
}

.certifications-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  max-width: 1200px;
  margin: 0 auto;
}

.section-heading {
  color: #1c0757;
  font-size: 2.8rem;
  font-weight: 700;
  line-height: 1.2;
  text-align: center;
  margin: 20px 0;
  padding-top: 20px;
}

.slideshow-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
}

.arrow-button {
  background-color: #ffffff;
  border: none;
  font-size: 2rem;
  color: #080835;
  cursor: pointer;
  border-radius: 50%;
  padding: 10px;
  transition: transform 0.2s ease, background-color 0.2s ease;
}

.arrow-button:hover {
  transform: scale(1.1);
  background-color: #f0f0f0;
}

.certifications-display {
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-grow: 1;
  width: 100%;
}

.certification-card {
  flex: 0 1 30%;
  min-width: 250px;
  padding: 2rem;
  border-radius: 1rem;
  background: #ffffff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
}

.certification-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.2);
}

.cert-icon {
  display: block;
  width: 100px;
  height: auto;
  margin: 0 auto 15px;
  object-fit: contain;
}

.certification-card h3 {
  color: #1c0757;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.3;
  margin: 1rem 0;
}

.certification-card p {
  color: #333;
  font-size: 1rem;
  line-height: 1.6;
  margin: 0;
  text-align: justify;
}


/* Responsive Adjustments */
@media (max-width: 768px) {
  .slideshow-container {
    flex-direction: column;
    align-items: center;
  }

  .certifications-display {
    flex-direction: column;
    gap: 30px;
    max-width: 100%;
  }

  .certification-card {
    min-width: 100%;
  }

  .certification-card p {
    font-size: 0.9rem;
  }
}
