/* About Me Section Root */
.about-me-root {
    background-color: #eafaff; /* New background color */
    color: #00264d; /* Dark blue text for good contrast */
    padding: 2rem 1rem;
    font-family: 'Noto Serif', serif;
    text-align: center;
}

/* Quote Styling */
.about-me-quote {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
    color: #004080; /* Slightly darker blue for emphasis */
}

/* About Me Content Styling */
.about-me-content {
    font-size: 1rem;
    line-height: 1.6;
}

.about-me-content p {
    margin-bottom: 1rem;
    color: #00264d; /* Consistent dark blue for text */
}

/* Highlight Important Text */
.about-me-content strong {
    color: #0059b3; /* A brighter shade of blue for emphasis */
}

/* Responsive Adjustments */
@media (min-width: 768px) {
    .about-me-root {
        padding: 3rem 2rem;
    }

    .about-me-quote {
        font-size: 2rem;
    }

    .about-me-content {
        font-size: 1.2rem;
    }
}
