/* Scoped CSS Variables for Skills Page */
.skills-page {
    --bg-color: #10104a;
    --gradient-section: linear-gradient(180deg, #242474, #25254a);
    --gradient-section-alt: linear-gradient(180deg, #10104a, #181886);
    --accent-color: #000000;
    --text-color: #fbf6f6;
    --card-bg-color: linear-gradient(135deg, #ffffff, #f2f2f2);
    --card-border-color: #ddd;
    --card-hover-border-color: #ccc;
    --card-shadow: rgba(6, 2, 2, 0.862);
    --card-hover-shadow: rgba(0, 0, 0, 0.495);
    --font-family: Arial, sans-serif;
    background: var(--bg-color);
    color: var(--text-color);
    font-family: var(--font-family);
    padding: 15px;
}


.page-title {
    text-align: left; /* Align the text to the right */
    font-size: 5rem; /* Increase the font size for better visibility */
    font-weight: bold; /* Make it bold */
    color: var(white); /* Use an accent color for emphasis */
     /*margin-left: 2rem; Add some space to the right for better alignment */
    text-shadow: 4px 4px 6px rgba(255, 255, 255, 0.5); /* Add a shadow for a stylish effect */
}


/* Section styling */
.skills-technologies-section {
    text-align: center;
    padding: 2rem 1rem;
    background: var(--gradient-section);
    color: var(--accent-color);
    border-radius: 20px;
    margin-bottom: 2rem; /* Space between sections */
}

.skills-technologies-section:nth-of-type(2) {
    background: var(--gradient-section-alt); /* Alternate background for Data Science */
}

/* Section title */
.section-title {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
    color: var(--text-color) !important;
}

/* Card container styling */
.card-container {
    background: var(--card-bg-color);
    border: 1px solid var(--card-border-color);
    border-radius: 10px;
    box-shadow: 0 4px 8px var(--card-shadow);
    padding: 1rem;
    margin: 1rem auto;
    max-width: 600px;
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.card-container:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px var(--card-hover-shadow);
}

/* Title Card */
.title-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    background: var(--card-bg-color);
    border-radius: 20px;
    box-shadow: 0 2px 4px var(--card-shadow);
    cursor: pointer;
}

.title-card:hover {
    background: var(--card-bg-color); /* Keep consistent hover */
}

.title-card-content {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.title-card-title {
    font-size: 1.5rem;
    color: var(--accent-color);
    font-weight: bold;
}

.title-card .icon {
    font-size: 1.8rem;
    color: var(--accent-color);
    transition: transform 0.3s ease, color 0.3s ease;
}

.title-card:hover .icon {
    transform: scale(1.2);
}

/* Technologies Card */
.technologies-card {
    padding: 1rem;
    background: var(--card-bg-color);
    border-radius: 20px;
    box-shadow: 0 2px 4px var(--card-shadow);
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.technologies-card .subcategory-title {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.technologies-card .item {
    background-color: var(--card-bg-color);
    border-radius: 150px;
    padding: 0.5rem;
    box-shadow: 0 2px 4px var(--card-shadow);
    font-size: 1rem;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.technologies-card .item:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 8px var(--card-hover-shadow);
}

/* Ensure no bullet points */
.no-bullet {
    list-style-type: none;
    padding-left: 0;
}

/* Responsive Design */
@media (max-width: 768px) {
    .page-title {
        font-size: 2rem;
    }

    .section-title {
        font-size: 1.8rem;
    }

    .card-container {
        gap: 0.5rem;
    }

    .title-card-title {
        font-size: 1.3rem;
    }

    .technologies-card .subcategory-title {
        font-size: 1rem;
    }

    .item {
        font-size: 0.9rem;
    }

    .icon {
        font-size: 1.5rem;
    }
}

@media (max-width: 480px) {
    .skills-technologies-section {
        padding: 1.5rem 0.5rem;
    }

    .section-title {
        font-size: 1.5rem;
    }

    .title-card-title {
        font-size: 1.2rem;
    }

    .item {
        font-size: 0.8rem;
    }

    .icon {
        font-size: 1.2rem;
    }
}
