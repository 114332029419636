/* Navbar Style Start */
.navbar {
    position: fixed; /* Make sure the navbar is positioned in the document flow */
    z-index: 10;
    display: flex; 
    justify-content: space-between;
    align-items: center;
    padding: 10px 100px;
    background: #080835;
    box-shadow: 0px 5.333px 80px 0 rgba(0, 0, 0, 0.1);
    top: 0;
    left: 0;
    right: 0;
  }
  .navbar--logo{
    width: 210px; /* Fixed width */
    height: auto; /* Maintain aspect ratio */
    object-fit: contain; /* Prevent stretching */
  }
  .navbar--items > ul {
   
    list-style: none;
    display: flex;
    align-items: flex-end;
    gap: 22.667px;
    text-decoration: none;
    padding-left: 250px;
  }
  .navbar--items ul > li > a {
    text-decoration: none;
    color: #fff;
  }
  /* Navbar Content */
  .navbar--content {
    color: var(--darkblue);
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    cursor: pointer;
    font-family: 'Trebuchet MS', sans-serif;
  }
  .navbar--name {
    font-size: 25px;
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    color: white;
    margin-left: 15px;
  }
  .navbar--active-content {
    color: #000000;
  }
  /* Navbar Styler Ends */