/* Base styling */
#Contact {
    padding: 1.5rem;
    background-color: #eafaff;
    font-family: 'Roboto', sans-serif;
} 

.contact--heading--container { 
    text-align: center;
    margin-bottom: 1.5rem;
}

.sections--heading-contact {
    font-size: 2rem;
    color: #080835;
    font-weight: bold;
}

/* Form styling */
.contact--form--container {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    max-width: 400px;
    margin: 0 auto;
}

.contact-label {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    font-size: 1rem;
    color: #333;
}

.contact-input {
    padding: 0.8rem;
    border: 1px solid #ccc;
    border-radius: 10px;
    font-size: 1rem;
    width: 95%;
}

textarea.contact-input {
    resize: none;
    height: 120px;
}

/* Submit button */
.submit--btn-container {
    text-align: center;
}

.contact--form-btn {
    background-color: #080835;
    color: white;
    border: none;
    padding: 0.8rem 2rem;
    border-radius: 5px;
    font-size: 1.2rem;
    cursor: pointer;
    width: 100%;
    transition: background-color 0.3s ease;
}

.contact--form-btn:hover {
    background-color: #007bb5;
}

/* Responsive Design */
@media (max-width: 768px) {
    .sections--heading--contact {
        font-size: 1.8rem;
    }

    .contact-input {
        font-size: 1rem;
    }

    .contact--form-btn {
        font-size: 1rem;
    }
}

@media (max-width: 480px) {
    .sections--heading--contact {
        font-size: 1.5rem;
    }

    .contact--form--container {
        gap: 1rem;
    }

    .contact-input {
        padding: 0.7rem;
    }

    .contact--form-btn {
        padding: 0.7rem;
        font-size: 0.9rem;
    }
}
