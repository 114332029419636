.professional-exp-root {
    --bg-color: #f7f9fc;
    --gradient-exp: linear-gradient(180deg, #10104a, #eafaff);
    --accent-color: #ffffff;
    --btn-hover-color: #007bb5s;
    --text-color: #080835;
    --title-color: #ffffff;
    --secondary-text-color: #ddd;
    --card-border-color: #e0e0e0;
    overflow-x: hidden;
    border: none;
}

/* Base styling for the expertise section */
.expertise-section {
    text-align: center;
    padding: 3rem 1rem;
    background: var(--gradient-exp);
    color: var(--text-color);
    font-family: 'Roboto', sans-serif;
    position: relative;
    overflow: hidden; /* Ensures content doesn't exceed bounds */
    max-width: 100%; /* Limits width */
    box-sizing: border-box; /* Includes padding and borders in the width */
}

/* Section title */
.professional-exp-root .section-title {
    font-size: 2.8rem;
    color: var(--title-color) !important;
    margin-bottom: 2rem;
    text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.5);
}
.button-container {
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    gap: 15px; /* Add spacing between the buttons */
}
.button-container .internship-link {
    padding: 0.8rem 0.9rem; /* Increase or decrease for larger or smaller buttons */
    font-size: 1rem; /* Adjust text size */
    width: auto; /* Optional: Set a specific width */
    height: auto; /* Optional: Set a specific height */
    border-radius: 10px; /* Optional: Make corners more rounded */
}

/* Add hover effects for interactivity */
.button-container .internship-link:hover {
    background-color: #262689; /* Hover color */
    transform: scale(1.05); /* Slightly increase size on hover */
    transition: transform 0.3s ease, background-color 0.3s ease;
}


/* Slider container */
.internships-container {
    position: relative;
    overflow: hidden; /* Hide overflowing cards */
    height: 515px; /* Ensure container height matches card height */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; /* Container width matches viewport */
}

/* Internship cards */
.internship-card {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; /* Ensure card matches container */
    height: 100%; /* Ensure card matches container height */
    transform: translateX(100%); /* Default position is off-screen to the right */
    opacity: 0; /* Hide inactive cards */
    transition: transform 0.6s ease-in-out, opacity 0.6s ease-in-out;
    transform-origin: center;    
    background-color: #ffffff; /* Add background color to ensure they are visible */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Add shadow for better visibility */
    border-radius: 10px; /* Optional for rounded corners */
    box-sizing: border-box; /* Include padding and borders in the width/height */
    margin: 0 auto; /* Center card */

}

/* Only show the active card */
.internship-card.active {
    transform: rotateY(0deg); /* Back to normal */
    opacity: 1;
    z-index: 2; /* Ensure active card is on top */
    position: relative;
}

/* Hide non-active cards */
.internship-card:not(.active) {
    opacity: 0;
    z-index: 1;
}

/* Internship image */
.internship-image {
    max-height: 150px;
    /*object-fit: contain;  Ensures the image is resized proportionally to fit the container */
    margin-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 100%; /* Ensures the image doesn't overflow horizontally */
    max-width: 100%; /* Prevents the image from exceeding the card width */
    border-radius: 10px; /* Optional: Add rounded corners to match the card style */
    box-sizing: border-box;
    object-fit: cover; /* Ensures the image covers the container, may crop slightly */
}

/* Company name */
.company-name {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: #333;
}

/* Internship position */
.internship-position {
    font-size: 1.1rem;
    margin-bottom: 1rem;
    text-align: justify;
    line-height: 1.5;
    color: var(--text-color);
    margin-top: 30px;
    /*
    font-size: 1.2rem;
    color: #555; Softer gray 
    margin-bottom: 1rem;
    line-height: 1.6;
    text-align: justify;
    */
}

/* Internship date */
.internship-date {
    margin-bottom: 1.5rem;
    text-align: left;
    font-size: 1rem;
    color: #10104a;
    font-family: 'Noto Serif', serif;
}

/* Internship link */
.internship-link {
    display: inline-flex; /* Align icon and text */
    align-items: center; /* Vertically center icon and text */
    justify-content: center; /* Center content horizontally */
    padding: 0.75rem 1.5rem; /* Consistent padding */
    font-size: 1rem; /* Button text size */
    font-weight: bold; /* Bold text */
    color: white; /* Button text color */
    background-color: #080835; /* Set the background color */
    text-decoration: none; /* Remove underline */
    border-radius: 8px; /* Rounded corners */
    text-align: center; /* Center the text */
    white-space: nowrap; /* Prevent text wrapping */
    transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transitions */
}

/* Add space between icon and text */
.internship-link svg {
    margin-right: 8px;
}



.internship-link:hover {
    background-color: #262689; /* Slightly lighter shade on hover */
    transform: translateY(-3px); /* Add a lift effect on hover */
}

/* Dots container */
.dots-container {
    margin-top: 2rem;
}

.dot {
    display: inline-block;
    width: 14px;
    height: 14px;
    margin: 0 8px;
    background-color: #ddd;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.dot.active {
    background-color: var(--btn-hover-color);
}

/* Responsive Design */
@media (max-width: 768px) {
    .section-title {
        font-size: 2rem;
    }

    .internship-card {
        width: 90%; /* Adjust card width for smaller screens */
        margin: 0 auto;
    }

    .internship-image {
        max-height: 120px;
    }
}

@media (max-width: 480px) {
    .expertise-section {
        padding: 2rem 1rem;
    }

    .section-title {
        font-size: 1.8rem;
    }

    .internship-card {
        width: 95%; /* Adjust card width further for mobile screens */
        padding: 1rem;
        margin: 0 auto;
    }
}

