/* Contact Section */
.contact-section-wrapper {
  background: #f5fcff; /* Apply light blue background to the wrapper only for this section */
  padding: 20px 0; /* Adjust spacing around the contact section */
}

.contact-section {
  background: #ffffff; /* Keep the inner section white if desired */
  padding: 50px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0);
  max-width: 800px;
  margin: auto;
}

.contact-header {
  margin-bottom: 30px;
}

.contact-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #282938; /* Hardcoded heading color */
}

.contact-subtitle {
  font-size: 1.2rem;
  color: #7f8c8d; /* Specific subtitle color */
}

.contact-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-group {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
}

.form-item {
  flex: 1 1 calc(50% - 10px);
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.form-label {
  font-size: 1rem;
  font-weight: 500;
  color: #1c1e53; /* Hardcoded text color */
}

.form-input {
  padding: 12px;
  border: 1px solid #bdc3c7;
  border-radius: 8px;
  font-size: 1rem;
  color: #1c1e53; /* Hardcoded input text color */
  background: #ffffff; /* Specific white for input background */
  outline: none;
  transition: border 0.3s ease, box-shadow 0.3s ease;
}

.form-input:focus {
  border: 1px solid #5e3bee; /* Hardcoded primary color */
  box-shadow: 0 0 5px rgba(94, 59, 238, 0.5); /* Slight glow effect */
}

textarea.form-input {
  resize: none;
  height: 150px; /* Fixed height for better usability */
}

.form-submit {
  text-align: center;
}

.submit-button {
  border: 1px solid #080835; /* Specific primary border */
  background-color: #080835; /* Specific primary background */
  color: #ffffff; /* Hardcoded white text */
  padding: 12px 30px;
  font-size: 1.2rem;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.submit-button:hover {
  background-color: #ffffff; /* Invert colors on hover */
  color: #5e3bee;
  transform: translateY(-3px);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Slight shadow */
}

/* Responsive Design */
@media (max-width: 768px) {
  .form-group {
    flex-direction: column; /* Stack inputs vertically */
  }

  .form-item {
    flex: 1 1 100%; /* Full width for each input */
  }
}

@media (max-width: 480px) {
  .contact-title {
    font-size: 2rem; /* Adjust title size */
  }

  .contact-subtitle {
    font-size: 1rem; /* Adjust subtitle size */
  }

  .submit-button {
    font-size: 1rem; /* Adjust button size */
    padding: 10px 20px;
  }
}

body {
  background: #f5fcff; /* Match the section's background */
}
