/* Professional Experience Section */
.Professional-xp--section {
  background-color: var(--bg-shade);
  display: flex;
  padding: 60px 20px;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}

.Professional-xp--container-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
}

.Professional-xp--container {
  color: #1c0757;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 21.3px;
}

.Professional-xp--section--container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  width: 100%;
  max-width: 1200px;
  align-items: stretch;
}

.Professional-xp--section--card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  background: #fff;
  box-shadow: 0 0 16px rgba(33, 11, 146, 0.2);
  border-radius: 12px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  padding: 20px;
  width: 100%;
  max-width: 350px;
  box-sizing: border-box;
  flex: 1;
  height: auto;
  min-height: 100%;
}

.Professional-xp--section--card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(8, 8, 53, 0.4);
}

/* Image container for both companies and universities */
.Professional-xp--section--img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.Professional-xp--section--img img {
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
  margin-bottom: 36px;
}

.Professional-xp--section--title {
  color: var(--heading-color);
  font-size: 22px;
  font-weight: 700;
  line-height: 1.5;
}

.Professional-xp--text-md {
  color: var(--darkblue);
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: auto;
}

/* Portfolio Link Buttons (For Companies and Universities) */
.portfolio--link {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px; /* Space between the buttons */
  margin-top: 16px;
}

.btn-link {
  background-color: #ffffff; /* Button background color */
  color: #1c0757; /* Text color */
  border: none;
  border-radius: 6px;
  padding: 8px 12px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  text-decoration: none;
}

.btn-link:hover {
  background-color: #1c0757; /* Darker shade on hover */
  color: #ffffff; /* Text color changes on hover */
  transform: translateY(-2px);
}

/* Buttons */
.btn-github {
  color: var(--white);
  background-color: #080835;
  border: var(--github);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.95);
  gap: 16px;
}

.btn-github:hover {
  color: var(--github);
  background-color: var(--white);
}
.Professional-xp--date {
  font-family: 'Noto Serif', serif;
  font-size: 14px;
  font-weight: bold;
  color: var(--darkblue);
  margin-top: 10px; /* Adds spacing between the date and the previous text */
  margin-bottom: 10px; /* Adds spacing between the date and the previous text */
  line-height: 1.5;
}

/* Responsive Design */
@media (max-width: 768px) {
  .Professional-xp--container-box {
    flex-direction: column;
    align-items: center;
  }

  .Professional-xp--section--container {
    flex-direction: column;
    gap: 30px;
  }

  .Professional-xp--section--card {
    max-width: 100%;
  }

  .portfolio--link {
    flex-direction: column; /* Stacks buttons vertically on smaller screens */
  }
}
